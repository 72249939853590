$(function(){
    $('.delete-application').on('click', function(e){
        e.preventDefault();
        confirm("<h1>Aanmelding verwijderen</h1><p>Weet je zeker dat je de aanmelding wilt verwijderen?</p>", $(this), deleteConfirm);
    });
    $('.delete-duo').on('click', function(e){
        e.preventDefault();
        confirm("<h1>Duo verwijderen</h1><p>Weet je zeker dat je dit duo wilt verwijderen?</p>", $(this), deleteConfirm);
    });
    $('.delete-carousel').on('click', function(e){
        e.preventDefault();
        confirm("<h1>Carrousel verwijderen</h1><p>Weet je zeker dat je deze carrousel wilt verwijderen?</p>", $(this), deleteConfirm);
    });
    $('.delete-district-coordinator').on('click', function(e){
        e.preventDefault();
        confirm("<h1>Coördinator verwijderen</h1><p>Weet je zeker dat je deze coördinator wilt verwijderen?</p>", $(this), deleteConfirm);
    });
    $('.update-carousel-selection').on('change', function(){
        var val = $(this).val();
        var option = $(this).find('option:selected');
        if (typeof(option) !== "undefined" && val.length > 0)
        {
            var duo_carousel_id = (typeof(option.attr('data-carousel-id')) !== "undefined" ? option.attr('data-carousel-id') : null);
            $.ajax({
                url: '/wijkcoordinator/check-carousel-availability',
                type: 'post',
                data: {'chosen_carousel_id' : val, 'duo_carousel_id' : duo_carousel_id},
                success: function(data)
                {
                    var result = data[0];
                    if (result == '1')
                    {
                        showSubmitSelectCarousel();
                    }
                    else
                    {
                        hideSubmitSelectCarousel();
                    }
                }
            });
        }
        compareCarousel();
    });
    if ($('.beschikbaar').length > 0)
    {
        serializeBeschikbaarheid('moment_of_availability[zorgafnemer]');
        serializeBeschikbaarheid('moment_of_availability[mantelzorger]');
    }
    $(':input[name^="points_of_interest"]').on('change', function(){
        if ($('.carrousel-select').val().length > 0)
        {
            compareCarousel();
        }
    });
    if($('.carrousel-select').length > 0) {
        $('.agenda-check').on('click', function () {
            if (!$(this).hasClass('readonly') && $('.carrousel-select').val().length > 0) {
                compareCarousel();
            }
        });
    }
    $('#duo_carousel_id').on('change', function(){
        duoSelectCarousel($(this).val());
    });

    
    if ($('.update-carousel-selection').length > 0 && $('.carrousel-select').val().length > 0)
    {
        setTimeout(function(){
            compareCarousel();
        }, 1000);
    }
});

function deleteConfirm(elem, ret)
{
    $('#form-del').attr('action', $(elem).attr('data-action'));
    $('#form-del').submit();
    return false;
}

function compareCarousel()
{
    var carousel_id = $('.carrousel-select').val();
    if (carousel_id.length > 0)
    {
        var point_of_interest_ids_mantelzorger = [];
        var point_of_interest_ids_zorgafnemer = [];
        var ignore_zorgafnemer = false;
        var ignore_mantelzorger = false;


        $(':input[name^="points_of_interest[mantelzorger]"]:checked').each(function(){
            point_of_interest_ids_mantelzorger.push($(this).val());
        });
        $(':input[name^="points_of_interest[zorgafnemer]"]:checked').each(function(){
            point_of_interest_ids_zorgafnemer.push($(this).val());
        });

        if (typeof($('.vergelijk-megacontainer-wrapper').attr('data-ignore-zorgafnemer')) !== "undefined")
        {
            ignore_zorgafnemer = $('.vergelijk-megacontainer-wrapper').attr('data-ignore-zorgafnemer');
            ignore_mantelzorger = $('.vergelijk-megacontainer-wrapper').attr('data-ignore-mantelzorger');
        }

        $.fancybox.showLoading();
        $.ajax({
            url: '/wijkcoordinator/compare/' + carousel_id,
            type: 'post',
            data: {
                point_of_interest_ids_mantelzorger: JSON.stringify(point_of_interest_ids_mantelzorger),
                point_of_interest_ids_zorgafnemer: JSON.stringify(point_of_interest_ids_zorgafnemer),
                moments_of_availability_mantelzorger: $(':input[name="moment_of_availability[mantelzorger]"]').val(),
                moments_of_availability_zorgafnemer: $(':input[name="moment_of_availability[zorgafnemer]"]').val(),
                ignore_zorgafnemer: ignore_zorgafnemer,
                ignore_mantelzorger: ignore_mantelzorger
            },
            success: function(data)
            {
                $('.vergelijk-megacontainer-wrapper').empty().append(data);
                $('.autofill').keyup();
                $.fancybox.hideLoading();
            }
        });
    }

}

function duoSelectCarousel(carousel_id)
{
    $.fancybox.showLoading();
    $.ajax({
        type: 'post',
        data: {carousel_id: carousel_id},
        url: window.location.href,
        success: function(data)
        {
            $('.table-container').replaceWith(data);
            $.fancybox.hideLoading();
        }
    })
}

// wanneer carousel wordt gekozen die vol is
function hideSubmitSelectCarousel()
{
    $.fancybox('<h1>Carousel</h1><p>Deze carousel is vol, selecteer een andere carousel of verhoog het maximaal aantal toegestane duo\'s</p>');
    $('.buttons-before-table').hide();
}
function showSubmitSelectCarousel()
{
    $('.buttons-before-table').show();
}
