function initCreateActivity()
{
    $('#form_create_activity').on('submit', function(){
       
        var errors = [];
        var form = $('#form_create_activity');
        
        var date = form.find(':input[name="activity[date]"]').val();
        var daypart = form.find(':input[name="activity[daypart]"]').val();
        var hours = form.find(':input[name="activity[hours]"]').val();
        var person_of_interest_id = form.find(':input[name="activity[point_of_interest_id]"]').val();
        var person_id = form.find(':input[name="activity[person_id]"]').val();
        var message = form.find(':input[name="activity[message]"]').val();
        
        if (date.length === 0)
        {
            errors.push('Kies een datum');
        }
        if (daypart.length === 0)
        {
            errors.push('Kies een dagdeel');
        }
        if (hours.length === 0)
        {
            errors.push('Kies het aantal uren');
        }
        if (person_of_interest_id.length === 0)
        {
            errors.push('Kies een activiteit');
        }
        if (person_id.length === 0)
        {
            errors.push('Kies een persoon');
        }
        if (message.length === 0)
        {
            errors.push('Het is verplicht om een bericht bij te voegen');
        }
        if (errors.length > 0)
        {
            content = '<h1>Activiteit plannen</h1>';
            content += '<p>Niet alle verplichte velden zijn correct ingevuld:</p>';
            content += '<ul>';
            for (i=0; i<errors.length; i++)
            {
                content += '<li>' + errors[i] + '</li>';
            }
            content += '</ul>';
            $.fancybox(content);
            return false;
        }
        else
        {
            form.find(':input[name="activity[point_of_interest_id]"]').val(form.find(':input[name="activity[point_of_interest_id]"]').attr('data-id'))    
            form.find(':input[name="activity[person_id]"]').val(form.find(':input[name="activity[person_id]"]').attr('data-id'));
            return true;
        }
        
    });

    $('#activiteit-datum,#select-daypart,#activity-hours').on('change keyup', function(){
        createActivityReset(); 
    });
    $('#activiteit-activiteit').on('change keyup', function(){
        createActivityReset('activiteit-zorgontvanger');    
    });


    var $container = $('.content-input'),
    prevValue = '',
    activeSet = false;

    $container.find(':input').bind('change keyup blur', function () {
        activeSet = false;
        prevValue = '';

        $container.find('li').each(function () {
            var $li = $(this),
            $field = $li.find(':input') != undefined ? $li.find(':input') : $li.find('select'),
            tagName = $field.prop("tagName") != undefined ? $field.prop("tagName").toLowerCase() : 'none',
            value = 'fake';

            switch (tagName) {
                case 'select':
                    value = $field.find('option:selected').val();
                    break;
                case 'none':
                    value = 'none';
                    break;
                default:
                    value = $field.val();
                    break;
            }

            $li.removeClass('active');
            $li.addClass('inactive');

            if (value != 'none' && value != 'fake' && (value == undefined || value == 0 || value == '')) {
                if (!activeSet) {
                    $li.addClass('active');
                    $li.removeClass('inactive');
                }
                activeSet = true;
            }
            else if (value != 'none' && value != 'fake' && value != undefined && value != 0 && value != '') {
                $li.removeClass('inactive');
            }
            else if (value == 'none') {
                if (!$li.prev().hasClass('inactive')) {
                    $li.removeClass('inactive');
                    $li.addClass('active');
                    $li.find('.amount').text(prevValue);
                } else {
                    $li.addClass('inactive');
                    $li.removeClass('active');
                }
            }

            prevValue = value;
        });
    });

    $('#activiteit-activiteit').on('click', function(){
        if (!$(this).parents('li:first').hasClass('inactive')) 
        {
            createActivityGetActivities();    
        }
    });
    $('#get-persons').on('click', function(){
        if (!$(this).parents('li:first').hasClass('inactive')) 
        {
            createActivityGetPersons();    
        }    
    });
}

function createActivityGetActivities(callback)
{
    $.fancybox.showLoading();
    $.ajax({
        type: 'post',
        url: window.baseUrl + 'duos/activiteiten/getactivities',
        data: {
            'activity[date]': $(':input[name="activity[date]"]').val(), 
            'activity[daypart]': $(':input[name="activity[daypart]"]').val(), 
            'activity[hours]': $(':input[name="activity[hours]"]').val(),
        },
        success: function(data)
        {
            $.fancybox(data);
            $('#popup-zijmenu li').click(function(){
                $('#popup-zijmenu li, .popup-inhoud').removeClass('active');
                $(this).addClass('active');
                $('.popup-inhoud:eq('+$(this).index()+')').addClass('active');
            });
            $('.checkradiobox-overzicht li').click(function(){
                if(!$(this).hasClass('nonactive')){
                    $(this).find('.keuze').prop( "checked", true );
                    $('.checkradiobox-overzicht li').removeClass('active');
                    $(this).addClass('active');
                }
            });
            $('.closefancybox').click(function(){
                $.fancybox.close();
                return false;
            });
            $('#form_points_of_interests .standard-button').on('click', function(){
                var radio = $('#form_points_of_interests :input[name="point_of_interest_id"]:checked');
                if (typeof(radio) !== "undefined" && radio.length > 0)
                {
                    var value = radio.val();
                    var label = radio.attr('data-label');
                    $('#activiteit-activiteit').val(label).attr('data-id', value).change();
                    if(callback !== undefined) callback(value, label);
                    $.fancybox.close();
                }
                else
                {
                    $('#form_points_of_interests').prepend('<span class="error">Er is nog geen interessegebied gekozen</span>');
                }
            });
        }
    });
}

function createActivityGetPersons()
{
    $.fancybox.showLoading();
    $.ajax({
        type: 'post',
        url: window.baseUrl + 'duos/activiteiten/getpersons',
        data: {
            'activity[date]': $(':input[name="activity[date]"]').val(), 
            'activity[daypart]': $(':input[name="activity[daypart]"]').val(), 
            'activity[hours]': $(':input[name="activity[hours]"]').val(),
            'activity[point_of_interest_id]': $('#activiteit-activiteit').attr('data-id')
        },
        success: function(data)
        {
            $.fancybox(data);
            var zorgaanbieders = $('.popup-zorgontvangers-kolom').length;
            var huidigkolom = 0;
            $('.popup-zorgontvangers-paginas').click(function(){
                $('.popup-zorgontvangers-kolom, .popup-zorgontvangers-paginas').removeClass('active');
                $('.popup-zorgontvangers-kolom:eq('+$(this).index()+'), .popup-zorgontvangers-paginas:eq('+$(this).index()+')').addClass('active');
            });
            $('.popup-zorgontvangers-kolom-keuze').click(function(){
                $(this).find('.keuze').attr('checked', true);
                $('.popup-zorgontvangers-kolom-keuze').removeClass('active');
                $(this).addClass('active');
            });
            $('.closefancybox').click(function(){
                $.fancybox.close();
                return false;
            });
            $('#form_persons .standard-button').on('click', function(){
                var radio = $('#form_persons :input[name="person_id"]:checked');
                if (typeof(radio) !== "undefined" && radio.length > 0)
                {
                    var value = radio.val();
                    var label = radio.attr('data-label');
                    $('#activiteit-zorgontvanger').val(label).attr('data-id', value).change();
                    $.fancybox.close();
                }
                else
                {
                    $('#form_persons').prepend('<span class="error">Er is nog geen persoon gekozen</span>');
                }
            });
        }
    });    
}

function createActivityReset(id)
{
    if (typeof(id) === "undefined")
    {
        $('#activiteit-activiteit').val('');
        $('#activiteit-zorgontvanger').val('');    
    }
    else
    {
        $('#' + id).val('');
    }
}