$(function(){
    initFlashMessages(); 
});

// js alert
function addFlashMessage(type, message)
{
    $('body').append('<div class="alert alert-' + type + '">' + message + '</div>');
    initFlashMessages();
}

function initFlashMessages()
{
    if ($('.alert').length > 0)
    {
        setTimeout(function(){
            $('.flash-background').fadeOut(500);
            }, 4000);
    }
    if ($('.formerrors').length > 0)
    {
        $('.formerrors ul li').each(function(){
            var fieldname = $(this).attr('data-field');
            if (fieldname.indexOf('.') !== -1)
            {
                var nr = 0;
                var fieldname_new = '';
                for (var i = 0, len = fieldname.length; i < len; i++) {
                    part = fieldname[i]; 
                    if (part == '.')
                    {
                        part = (nr === 0 ? '[' : '][');
                        nr++;
                    }
                    fieldname_new += part;
                }
                fieldname = fieldname_new + ']';
            }
            $(':input[name="' + fieldname + '"]').addClass('form-error');
        });
    }

    $('.alert .close').click(function() {
        $('.flash-background').fadeOut(500);
    });
}
