$(function(){
    faq();
    initDuos();
});

function initDuos(data)
{
    var container = $('#content-activiteitplannen .custom-other');
    container.on('click', function(){
        setActivePerson($(this).find('.custom-option-right').attr('data-person-id'), $('#content-activiteitplannen').attr('data-callback'));
    });
    if (typeof(initCreateActivity) == 'function')
    {
        initCreateActivity();
    }


    if(data) {
        data = groupBy(data, 'name');
        var date = data['activity[date]'][0].value;
        var daypart = data['activity[daypart]'][0].value;
        var hours = data['activity[hours]'][0].value;
        var message = data['activity[message]'][0].value;

        console.log(daypart);

        $('input[name="activity[date]"]').val(date);
        $('select[name="activity[daypart]"] option[value='+daypart+']').prop('selected', true);
        $('select[name="activity[hours]"] option[value='+hours+']').prop('selected', true);
        $('textarea[name="activity[message]"]').val(message).keyup();
    }
}
var groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

function faq() {
    var $questions = $('.faq-vraag'),
        $parentContainer = $('.faq-vraag-content'),
        $container = $parentContainer.find('.content'),
        $usefulContainer = $parentContainer.find('.useful'),
        $nonUsefulContainer = $parentContainer.find('.not-useful');

    $questions.click(function () {
        var $this = $(this),
            id = $this.data('id'),
            content = $this.find('.text').html();

        $usefulContainer.hide();
        $nonUsefulContainer.hide();

        $questions.removeClass('faq-vraag-active');
        $this.addClass('faq-vraag-active');

        $container.attr('data-id', id);
        $container.html(content);
    });

    $container.on('click', '.no, .yes', function () {
        var $this = $(this),
            type = $this.hasClass('yes') ? 'yes' : 'no',
            id = $container.attr('data-id');

        $.post(window.baseUrl + route('duos.help.update').replace('{id}', id), {type: type}, function () {
            if (type == 'no') {
                $usefulContainer.hide();
                $nonUsefulContainer.slideDown('slow');
            }
            else {
                $usefulContainer.slideDown('slow');
                $nonUsefulContainer.hide();
            }
        });

        return false;
    });

    $questions.first().click();
}


function setActivePerson(person_id, callback)
{                                           
    $.fancybox.showLoading();

    $.post(window.baseUrl + 'duos/setactiveperson/' + person_id,  {person_id: person_id}, function(data) {
        if (typeof(callback) !== "undefined")
        {
            switch (callback)
            {
                case 'refreshPage':
                    var data = $('#form_create_activity').serializeArray();
                    refreshPage('/duos/activiteiten/partial', $('#create-activity-wrapper'), initDuos.bind(null, data));
                    break;
            }
        }else{
            data = JSON.parse(data);
            $('.custom-select').html(data.view);
            $('.replace-name').text(data.name);
            initDuos();
        }

        $.fancybox.hideLoading();
    });
}

function getActivities()
{
    $.fancybox.showLoading();
    $.ajax({
        type: 'post',
        url: '/duos/activiteiten/getactivities',
        data: {date: $(':input[name="activity[date]"]').val(), dayoption: $(':input[name="activity[dayoption]"]').val(), hours: $(':input[name="activity[hours]"]').val()},
        success: function(data)
        {
            $.fancybox(data);
        }
    })
}