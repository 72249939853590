var currentPage = 1;

function handleActivity(value, label)
{
    $('.choose-activity').text(label);
    $('#activiteit-activiteit').val(value);
}
function removeMessage(e) {
    console.log(e);
    // window.location.href = url;
}
$(function() {
    var $messageList = $('.message-list');



    $('.more-messages').click(function() {
        $.fancybox.showLoading();
        var $this = $(this),
            type  = $('.filter-type option:selected').val(),
            id = $('.filter-groep .active').attr('data-id');

        currentPage++;
        $.get('?page=' + currentPage + '&type=' + type, {id:id}, function(data) {
            data = JSON.parse(data);

            if(data.lastPage == currentPage) {
                $this.hide();
            }
            if(data.lastPage >= currentPage) {
                $messageList.append(data.view);
            }
            $.fancybox.hideLoading();
        });
    });

    $('.choose-activity').click(function() {
        createActivityGetActivities(handleActivity);
    });


    $('.message-who').change(function() {
        var $this  = $(this),
            $images = $('.message-profile-images img'),
            id = $this.find('option:selected').val();

        $images.hide();
        $('#person_' + id).show();
    });

    $('.filter-type').change(function() {
        $.fancybox.showLoading();

        currentPage = 1;
        var $this = $(this),
            type  = $this.find('option:selected').val(),
            id = $('.filter-groep .active').attr('data-id');

        $('.more-messages').show();
        $.get('?page=1&type=' + type, {id:id}, function(data) {
            data = JSON.parse(data);
            if(data.lastPage == 1) {
                $('.more-messages').hide();
            }

            $('.message-list').html(data.view);

            $.fancybox.hideLoading();
        });
    });

    $messageList.on('click','.reply-btn', function() {
        var $this = $(this),
            $parent = $this.parent(),
            $replyBox = $parent.find('.reply');

        $replyBox.slideToggle();

        return false;
    });

    $messageList.on('click', '.delete-message', function() {
        var $this = $(this),
            url = $this.attr('href');

        confirm('Weet u zeker dat u dit bericht wilt verwijderen?<br><br>', null, function() {
           $.post(url, {_method: 'DELETE'}, function () {
                $this.closest('.prikbord-bericht-container').remove();
            });
        });
        return false;
    });

    $messageList.on('click', '.reply-submit-btn', function() {
        var $this = $(this),
            url = $this.data('url'),
            $parent = $this.parent(),
            $container = $parent.parent(),
            $replies = $container.find('.replies'),
            $message = $parent.find('.message'),
            message_id = $this.data('id'),
            person_id = $this.data('person-id'),
            message = $message.val(),
            replyId = $this.data('reply-id');

            if(replyId == undefined) replyId = 0;

        if(message.length < 2) {
            if($parent.find('.error').length == 0) {
                $parent.prepend('<p class="error">Vul uw reactie in.</p>');
            }
            return false;
        }
        else{
            $parent.find('.error').remove();
        }

        $.post(url, { content: message, person_id:person_id, message_id:message_id, reply_id: replyId }, function(data) {
            data = JSON.parse(data);

            if(data.edit) {
                $this.closest('li').find('.content').text(message).show();
                $this.closest('li').find('.edit').hide();
            }else {
                var html = '<li>';
                html += '<div class="content">' + data.message.content + '</div>';
                html += '<div class="edit hide">';
                html += '<textarea class="message">' + data.message.content + '</textarea>';
                html += '<a class="standard-button reply-cancel-btn">Annuleren</a>';
                html += '<input type="submit" data-reply-id="' + data.message.id + '" data-person-id="' + data.message.person_id + '" data-id="' + data.message.message_id + '" data-url="' + url + '" class="standard-button reply-submit-btn" value="Opslaan">';
                html += '</div>';
                html += '<a href="' + route('board.remove.reply').replace('{id}', data.message.id) + '" class="remove-message">Verwijderen</a>';
                html += '<a href="#" class="edit-message">Aanpassen</a>';
                html += '</li>';

                $replies.find('ul').prepend(html);

                $this.closest('.prikbord-bericht-buttons').find('.reply').slideUp();
                $message.val('');
            }
        });

        return false;
    });

    $messageList.on('click', '.remove-message', function() {
        var $this = $(this),
            url = $this.attr('href');

        $.post(url, function(data) {
            $this.closest('li').slideUp('slow', function() {
               $this.remove();
            });
        });

        return false;
    });

    $messageList.on('click', '.edit-message', function() {
        var $this = $(this),
            $parent = $this.parent(),
            $content = $parent.find('.content'),
            $edit = $parent.find('.edit');

        $content.hide();
        $edit.show();

        return false;
    });

    $messageList.on('click', '.reply-cancel-btn', function() {
        var $this = $(this),
            $parent = $this.closest('li'),
            $content = $parent.find('.content'),
            $edit = $parent.find('.edit'),
            $textarea = $parent.find('textarea');

        $content.show();
        $edit.hide();
        $textarea.val($content.text());

        return false;
    });


    // Contact Moments Page
    var page = 1;
    var $messages = $('.contactmomenten-bericht');
    $('.contactmomenten-berichten').on('click', '.contactmomenten-bericht', function() {
        var $this = $(this),
            name = $this.find('.from').text(),
            to = $this.find('.to').text(),
            type = $this.find('.type').text();
            verstuurd = $this.find('.verstuurd').text();
            date = $this.find('.date').text(),
            subject = $this.find('.subject').text(),
            message = $this.find('.message').text(),
            $content = $('.contactmoment'),
            $messages = $('.contactmomenten-bericht');

        $content.find('.from').text(name);
        $content.find('.to').text(to);
        $content.find('.type').text(type);
        $content.find('.verstuurd').text(verstuurd);
        $content.find('.date').text(date);
        $content.find('.subject').text(subject);
        $content.find('.message').text(message);

        $messages.removeClass('contactmomenten-bericht-active');
        $this.addClass('contactmomenten-bericht-active');

        return false;
    });

    $messages.first().click();

    $('.more').click(function() {
        page++;
        $.get('?page=' + page, function(data) {
            var data = JSON.parse(data);
            var $data = $(data.view);
            var desktopHtml = $($data[0]).html();
            var mobileHtml = $($data[2]).html();

            $('.contactmomenten-berichten-desktop').append(desktopHtml);
            $('.contactmomenten-berichten-mobiel').append(mobileHtml);

            if(data.lastPage == page) {
                $('.more').hide();
            }
        });
    });
});