$(function() {
    $('#wijzigen').click(function() {
        $('.content-blok').find(':input').each(function() {
            var $this = $(this),
                value = $this.val();

            $this.attr('data-current', value);
        });
    });

    $('.wijzigingen-annuleer').click(function() {
        $('.content-blok').find(':input').each(function() {
            var $this = $(this),
                value = $this.attr('data-current');

            $this.val(value);
        });
    });
});