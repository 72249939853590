// var callback = false;

$(function(){
    JSInit();
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    if($.active == 0) {
        $('.fancybox-ajax').fancybox({
            type        : 'ajax',
            afterShow   : function (){
                JSInit();
            }
        });

        $('.fancybox-faq').fancybox({
            padding: 30,
            fitToView: true,
            type: 'ajax',
            afterShow: function () {
                JSInit();
                $('.faq-container').closest('.fancybox-wrap').animate({
                    width: 960,
                    left: ($(window).width() - 960) / 2
                });
            }
        });
    }
});

function JSInit()
{
    $('.change-go-to').on('change', function(){
        var option = $(this).find('option[value="' + $(this).val() + '"]');
        if (option.length > 0 && option.attr('data-url').length > 0)
        {
            window.location = option.attr('data-url');
        }
    });

    $('.fancybox-sluiten').on('click', function(){
        $.fancybox.close();
        return false;
    });

    $('.fancybox').fancybox();

    $(".agenda-check").on('click', function(){
        console.log('test');
        if (!$(this).hasClass('readonly'))
        {
            $(this).toggleClass("beschikbaar");
            serializeBeschikbaarheid($(this).attr('data-field'));
        }
    });

    $('.autofill').on('keyup', function(){
        var field = $('#autofill_' + $(this).attr('data-af-id'));
        field.text($(this).val());
    });

    $( ".datepicker" ).datepicker({
        yearRange: "-120:+100",
        closeText: 'Sluiten',
        prevText: 'Vorige maand',
        nextText: 'Volgende maand',
        currentText: 'Vandaag',
        monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
        monthNamesShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        dayNamesShort: ['zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat'],
        dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
        weekHeader: 'Wk',
        dateFormat: 'd MM yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        changeMonth: true,
        changeYear: true
    });

    $('.submit-form').on('click', function(){
        var form = $(this).parent('form');
        if (typeof($(this).attr('data-form-id')) !== "undefined")
        {
            form = $('form#' + $(this).attr('data-form-id'));
        }
        form.submit();
        return false;
    });

    $('.password-reset').click(function() {
        $.fancybox({
            width: 400,
            height: 250,
            autoSize: false,
            href: window.baseUrl + 'password/reset',
            type: 'ajax'
        });

        return false;
    });

    $('.sortable').sortable();
    $('.sortable-save-sort').sortable({
        stop: function(event, ui){

            handleSortData();
        }
    });
    if ($('.sortable-save-sort').length > 0)
    {
        handleSortData();
    }

    $('.content-fancybox-gallery .fancybox').each(function(){
        $(this).attr('data-fancybox-group','data-fancybox-group');
    });
    
    $(".input-holder").click(function(){
        $(this).find(".input-placeholder").addClass("input-placeholder-clicked");
    });

    $(".input-holder").focusin(function(){
        $(this).find(".input-placeholder").addClass("input-placeholder-clicked");
    });

    /* radiobuttons  privacy*/
    $('.radioblokken .left, .radioblokken .right').click(function(){
        $(this).find('.keuze').prop( "checked", true );
        $(this).parent().find('.left, .right').removeClass('active');
        $(this).addClass('active');
    });

    $('.radioblokken input[type=radio]').each(function() {
        var $this = $(this),
            $parent = $this.closest('.checkradiobox-custom').parent();
        if($this.prop('checked')) {
            $parent.addClass('active');
        }else{
            $parent.removeClass('active');
        }
    });

    // wijzigen
    $('#wijzigen').click(function(){
        $(this).siblings('.informatie-blok').removeClass('nonactive');
        $(this).addClass('active');
    });

    $('.wijzigingen-annuleer').click(function(){
        $('.informatie-blok').addClass('nonactive');
        $('#wijzigen').removeClass('active');
    });

    // wijzigen gegevens
    $('.gegevens #wijzigen').click(function(){
        $(this).siblings('.informatie-blok').removeClass('nonactive');
        $(this).addClass('active');
    });

    $('.gegevens .wijzigingen-annuleer').click(function(){
        $('.informatie-blok').addClass('nonactive');
        $('.gegevens #wijzigen').removeClass('active');
    });

    // wijzigen locatie
    $('.locatie #wijzigen').click(function(){
        $(this).siblings('.informatie-blok').removeClass('nonactive');
        $(this).addClass('active');
    });

    $('.locatie .wijzigingen-annuleer').click(function(){
        $('.informatie-blok').addClass('nonactive');
        $('.locatie #wijzigen').removeClass('active');
    });

    $('.form-ajax').on('submit', function(){

        $.fancybox.showLoading();
        $.ajax({
            url: $(this).attr('action'),
            type: 'post',
            data: $(this).serialize(),
            success: function(data)
            {
                $.fancybox(data);
                JSInit();
            }
        })
        return false;

    });
}

function getObjectFromArray(array, property, value) {
    return array.filter(function (obj) {
        return obj[property] == value;
    });
}

function route(name) {
    return getObjectFromArray(routes, 'name', name)[0].path;
}

function handleSortData()
{
    var data = [];
    $('.sortable-save-sort li :input').each(function() {
        if ($(this).val().length > 0)
        {
            data.push($(this).val());
        }
    });
    $(':input[name="save_sort"]').val(JSON.stringify(data));
}

function confirm(msg, elem, callback)
{
//    console.log(elem);
    var ret = false;
    msg += '<br><br><a href="#" class="confirm yes standard-button">Doorgaan</a>';

    $.fancybox(msg, {
        closeEffect: 'none',
        beforeShow: function(){

        },
        afterShow: function() {
            $(".confirm").on("click", function(event){
                if($(event.target).is(".yes")){
                    if(callback) callback.call(false, elem, ret);
                } else if ($(event.target).is(".no")){
                    ret = false;
                }
                $.fancybox.close();
            });
        },
        afterClose: function() {
            $('.fancybox-overlay').remove();
        }
    });
}

function serializeBeschikbaarheid(field)
{
    var momenten = [];
    var i = 0;
    $('.beschikbaar[data-field="' + field + '"]').each(function(){
        momenten[i] = $(this).attr('data-moment_of_availability');
        i++;
    });
    $(':input[name="' + field + '"]').val(JSON.stringify(momenten));

}

function refreshPage(url, replaceElement, callback)
{
    $.ajax({
        url: url,
        success: function(data)
        {
            replaceElement.replaceWith(data);
            if (callback && typeof(callback) == "function")
            {
                callback();
            }
            $.fancybox.hideLoading();
        }
    })
}


