/*MOBILE MENU*/
jQuery(function(){
    //menuCorrect();
    //jQuery(window).resize(function() {
        //menuCorrect(); 
    //});                                       
    
    jQuery('.open-mobile-menu').click(function(){
        jQuery('.mobilemenu-container').fadeIn(800);
        jQuery(this).fadeOut();
        jQuery('.close-mobile-menu').fadeIn(800);
        $('html, body').css({
            'overflow': 'hidden',
            'height': '100%'
        });
        $('#top-website-mobile, #mobilemenu-laag').css({           
            'height': '0px'
        });
        return false;    
    }); 

    jQuery('.close-mobile-menu').click(function(){
        jQuery('.mobilemenu-container').fadeOut(800);
        jQuery('.open-mobile-menu').fadeIn(800);
        jQuery(this).fadeOut();
        $('html, body').css({
            'overflow': 'auto',
            'height': 'auto'
        });
        $('#top-website-mobile, #mobilemenu-laag').css({           
            'height': '0px'
        });
        return false;
    });  
});

/*MOBILE MENU*/



